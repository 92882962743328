*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

.logo_ps {
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 999;
    width: 10vw;
    height: 10vw;
}

.logo_dv {
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: 999;
    height: 10vw;
}
